import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { AccountActions } from './account.actions';
import { of, withLatestFrom } from 'rxjs';
import { getMembershipNumber } from '@aaa/emember/store-membership';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { ExecuteService } from '../services/execute.service';
import {
  Membership,
  MembershipMGetMemberInfoEventPayload,
  MembershipMMethod,
  MembershipMOperationExecuteChangeEventPayload,
  ThreePointAuth,
} from '@aaa/interface-joinRenew-membership-membershipM';
import { M } from '../m.type';
import { AccountAddressValues, AccountEmailValues, AccountPhoneValues } from './account.model';
import { ClubApp } from '@aaa/emember/types';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { getThreePointAuth } from './account.selectors';
import { RequestError, RequestErrorType } from '../generic-errors';
import { checkMembershipErrorsMSystem } from '../check-membership-errors-m-system';
import { checkOperationErrorsMSystem } from '../check-operation-errors-m-system';
import { getClearCacheSettings } from '../utils/get-cache-settings';

@Injectable({ providedIn: 'root' })
export class AccountMSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Shelby, ClubApp.Northampton]));
  executeService = inject(ExecuteService);

  updatePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updatePhone),
      withLatestFrom(this.store.select(getMembershipNumber), this.store.select(getThreePointAuth)),
      switchMap(([{ data }, memberNumber, threePointAuth]) =>
        this.getMemberInfo(memberNumber, threePointAuth).pipe(
          concatMap((executionData) => {
            return this.savePhone(executionData, data, memberNumber, threePointAuth).pipe(
              map((accountDetails) => AccountActions.updatePhoneSucceeded({ accountDetails })),
              catchError((error) => of(AccountActions.updatePhoneFailed({ error }))),
            );
          }),
          catchError((error) => of(AccountActions.updatePhoneFailed({ error }))),
        ),
      ),
    ),
  );

  updateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateEmail),
      withLatestFrom(this.store.select(getMembershipNumber), this.store.select(getThreePointAuth)),
      switchMap(([{ data }, memberNumber, threePointAuth]) =>
        this.getMemberInfo(memberNumber, threePointAuth).pipe(
          concatMap((executionData) => {
            return this.saveEmail(executionData, data, memberNumber, threePointAuth).pipe(
              map((accountDetails) => AccountActions.updateEmailSucceeded({ accountDetails })),
              catchError((error) => of(AccountActions.updateEmailFailed({ error }))),
            );
          }),
          catchError((error) => of(AccountActions.updateEmailFailed({ error }))),
        ),
      ),
    ),
  );

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateAddress),
      withLatestFrom(this.store.select(getMembershipNumber), this.store.select(getThreePointAuth)),
      switchMap(([{ data }, memberNumber, threePointAuth]) =>
        this.getMemberInfo(memberNumber, threePointAuth).pipe(
          concatMap((executionData) =>
            this.saveAddress(executionData, data, memberNumber, threePointAuth).pipe(
              map((accountDetails) => AccountActions.updateAddressSucceeded({ accountDetails })),
              catchError((error) => of(AccountActions.updateAddressFailed({ error }))),
            ),
          ),
          catchError((error) => of(AccountActions.updateAddressFailed({ error }))),
        ),
      ),
    ),
  );

  saveEmail(
    executionData: string,
    data: Partial<AccountEmailValues>,
    memberNumber: string,
    threePointAuth: ThreePointAuth,
  ) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      executionData,
      threePointAuth,
      operation: Operation.UPDATE,
      changePropsData: {
        users: [
          {
            type: 'primary',
            membershipNumber: memberNumber,
            email: data.email,
            options: [],
          },
        ],
      },
      method: MembershipMMethod.OPERATION_EXECUTE,
    };

    const payload: OperationExecutePayload = {
      membershipEvent,
      cacheSettings: getClearCacheSettings(memberNumber),
      operation: Operation.UPDATE,
    };

    return this.executeService.execute<M.MemberInfoUpdateResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        const membershipError = !validateObject || !!validateObject?.meta?.isError;
        if (membershipError) {
          throw new RequestError(RequestErrorType.UpdateEmail, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject);
        }

        const memberInfo = validateObject.response.response.Result.Membership[0] as Membership;
        return new M.AccountInfo(memberInfo);
      }),
    );
  }

  savePhone(
    executionData: string,
    data: Partial<AccountPhoneValues>,
    memberNumber: string,
    threePointAuth: ThreePointAuth,
  ) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      operation: Operation.UPDATE,
      changePropsData: {
        users: [
          {
            type: 'primary',
            cellPhone: data.cell || '',
            homePhone: data.home || '',
            membershipNumber: memberNumber,
            options: [],
          },
        ],
      },
      executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
    };

    const payload: OperationExecutePayload = {
      membershipEvent,
      cacheSettings: getClearCacheSettings(memberNumber),
      operation: Operation.UPDATE,
    };

    return this.executeService.execute<M.MemberInfoUpdateResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        const membershipError = !validateObject || !!validateObject?.meta?.isError;
        if (membershipError) {
          throw new RequestError(RequestErrorType.UpdatePhone, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject);
        }

        const memberInfo = validateObject.response.response.Result.Membership[0] as Membership;
        return new M.AccountInfo(memberInfo);
      }),
    );
  }

  saveAddress(
    executionData: string,
    data: Partial<AccountAddressValues>,
    memberNumber: string,
    threePointAuth: ThreePointAuth,
  ) {
    const membershipEvent: MembershipMOperationExecuteChangeEventPayload = {
      operation: Operation.UPDATE,
      changePropsData: {
        address: {
          address1: data.address1 || '',
          address2: data.address2,
          cityName: data.city || '',
          postalCode: data.zipcode || '',
          stateProv: data.state || '',
        },
      },
      executionData,
      threePointAuth,
      method: MembershipMMethod.OPERATION_EXECUTE,
    };
    const payload: OperationExecutePayload = {
      membershipEvent,
      cacheSettings: getClearCacheSettings(memberNumber),
      operation: Operation.UPDATE,
    };

    return this.executeService.execute<M.MemberInfoUpdateResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        const membershipError = !validateObject || !!validateObject?.meta?.isError;
        if (membershipError) {
          throw new RequestError(RequestErrorType.UpdateAddress, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsMSystem(operationObject.error, operationObject);
        }

        const memberInfo = validateObject.response.response.Result.Membership[0] as Membership;
        return new M.AccountInfo(memberInfo);
      }),
    );
  }

  getMemberInfo(membershipNumber: string, threePointAuth: ThreePointAuth) {
    const payload: MembershipMGetMemberInfoEventPayload = {
      membershipNumber,
      threePointAuth,
      method: MembershipMMethod.GET_MEMBER_INFO,
    };

    return this.executeService.membershipQuery<M.MemberInfoResponseObject>(payload).pipe(
      map((validateObject) => {
        const membershipError = !!validateObject?.meta?.isError;

        if (membershipError) {
          checkMembershipErrorsMSystem(validateObject.error, validateObject);
        }

        return validateObject.response.executionData;
      }),
    );
  }
}
