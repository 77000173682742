import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { AccountActions } from './account.actions';
import { of, withLatestFrom } from 'rxjs';
import { AccountAddressValues, AccountPhoneValues } from './account.model';
import {
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateChangeAddressEventPayload,
  MembershipConnectSuiteRecostValidateChangePhoneEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { getMembershipNumber } from '@aaa/emember/store-membership';
import { ConnectSuite } from '../connect-suite.type';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { RequestError, RequestErrorType } from '../generic-errors';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { checkOperationErrorsConnectSuiteSystem } from '../check-operation-errors-connect-suite-system';

@Injectable({ providedIn: 'root' })
export class AccountConnectSuiteSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  executeService = inject(ExecuteService);

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateAddress),
      withLatestFrom(this.store.select(getMembershipNumber)),
      switchMap(([{ data }, memberNumber]) =>
        // 1. Validate
        this.validateAddress(data, memberNumber).pipe(
          concatMap((executionData) =>
            // 2. Save
            this.saveAddress(executionData, memberNumber),
          ),
          map(() => AccountActions.updateAddressSucceeded({})),
          catchError((error) => of(AccountActions.updateAddressFailed({ error }))),
        ),
      ),
    ),
  );

  updatePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updatePhone),
      withLatestFrom(this.store.select(getMembershipNumber)),
      switchMap(([{ data }, memberNumber]) =>
        // 1. Validate
        this.validatePhone(data, memberNumber).pipe(
          concatMap((executionData) =>
            // 2. Save
            this.savePhone(executionData, memberNumber),
          ),
          map(() => AccountActions.updatePhoneSucceeded({})),
          catchError((error) => of(AccountActions.updatePhoneFailed({ error }))),
        ),
      ),
    ),
  );

  validateAddress(data: Partial<AccountAddressValues>, memberNumber: string | null) {
    const payload: MembershipConnectSuiteRecostValidateChangeAddressEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ADDRESS,
      address: {
        address1: data.address1,
        address2: data.address2,
        cityName: data.city,
        postalCode: data.zipcode || '',
        StateProv: data.state,
      },
      memberNumber: memberNumber || '',
    };

    return this.executeService.membershipQuery<ConnectSuite.ValidateChangeAddressResponseObject>(payload).pipe(
      map((validateObject) => {
        if (validateObject.meta?.isError) {
          throw new RequestError(RequestErrorType.UpdateAddress, validateObject);
        }

        return validateObject.response.executionData;
      }),
    );
  }

  saveAddress(executionData: string, memberNumber: string | null) {
    const operation = Operation.UPDATE;
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      operation,
      cacheSettings: getClearCacheSettings(memberNumber),
      executionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
    };
    const executePayload: OperationExecutePayload = {
      membershipEvent,
      operation,
      paymentEvent: null,
    };

    return this.executeService.execute<ConnectSuite.ValidateChangeAddressResponseObject>(executePayload).pipe(
      map(({ validateObject, operationObject }) => {
        if (validateObject?.meta?.isError) {
          throw new RequestError(RequestErrorType.UpdateAddress, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
        }

        return validateObject;
      }),
    );
  }

  validatePhone(data: AccountPhoneValues, memberNumber: string | null) {
    const payload: MembershipConnectSuiteRecostValidateChangePhoneEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_PHONE,
      businessPhone: data.business,
      homePhone: data.home,
      cellPhone: data.cell,
      memberNumber: memberNumber || '',
    };

    return this.executeService.membershipQuery<ConnectSuite.ValidateChangePhoneResponseObject>(payload).pipe(
      map((validateObject) => {
        if (validateObject.meta?.isError) {
          throw new RequestError(RequestErrorType.UpdatePhone, validateObject);
        }

        return validateObject.response.executionData;
      }),
    );
  }

  savePhone(executionData: string, memberNumber: string | null) {
    const operation = Operation.UPDATE;
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      operation,
      cacheSettings: getClearCacheSettings(memberNumber),
      executionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
    };
    const payload: OperationExecutePayload = {
      membershipEvent,
      operation,
      paymentEvent: null,
    };

    return this.executeService.execute<ConnectSuite.ValidateChangeAddressResponseObject>(payload).pipe(
      map(({ validateObject, operationObject }) => {
        if (validateObject?.meta.isError) {
          throw new RequestError(RequestErrorType.UpdatePhone, validateObject);
        }

        const operationError = !!operationObject?.meta?.isError;
        if (operationError) {
          checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
        }

        return validateObject;
      }),
    );
  }
}
