import { createReducer, on } from '@ngrx/store';
import { AccountState } from './account.model';
import { AccountActions } from './account.actions';
import { RequestStatus } from '../../../types/request-status';
import { FormMode } from '../../modules/share/form.utils';

export const initialAccountState: AccountState = {
  editAddress: false,
  addressFormRequestStatus: RequestStatus.UNSENT,
  addressFormRequestError: null,
  phoneFormRequestStatus: RequestStatus.UNSENT,
  phoneFormRequestError: null,
  editPhoneType: null,
  emailFormRequestStatus: RequestStatus.UNSENT,
  emailFormRequestError: null,
  editEmailFormMode: FormMode.VIEW,
};

export const accountReducer = createReducer(
  initialAccountState,
  // Address
  on(AccountActions.editAddress, (state) => ({
    ...state,
    editAddress: !state.editAddress,
  })),
  on(AccountActions.updateAddress, (state) => ({
    ...state,
    addressFormRequestError: null,
    addressFormRequestStatus: RequestStatus.RUNNING,
  })),
  on(AccountActions.updateAddressSucceeded, (state) => ({
    ...state,
    addressFormRequestStatus: RequestStatus.SUCCESS,
    editAddress: false,
  })),
  on(AccountActions.updateAddressFailed, (state, { error }) => ({
    ...state,
    addressFormRequestError: error,
    addressFormRequestStatus: RequestStatus.FAILED,
  })),
  on(AccountActions.removeAddressError, (state) => ({
    ...state,
    addressFormRequestError: null,
  })),

  // Phone
  on(AccountActions.editPhoneType, (state, { phoneType }) => ({
    ...state,
    editPhoneType: phoneType,
  })),
  on(AccountActions.updatePhone, (state) => ({
    ...state,
    phoneFormRequestError: null,
    phoneFormRequestStatus: RequestStatus.RUNNING,
  })),
  on(AccountActions.updatePhoneSucceeded, (state) => ({
    ...state,
    phoneFormRequestStatus: RequestStatus.SUCCESS,
    editPhoneType: null,
  })),
  on(AccountActions.updatePhoneFailed, (state, { error }) => ({
    ...state,
    phoneFormRequestError: error,
    phoneFormRequestStatus: RequestStatus.FAILED,
  })),
  on(AccountActions.removePhoneError, (state) => ({
    ...state,
    phoneFormRequestError: null,
  })),

  on(AccountActions.updateEmail, (state) => ({
    ...state,
    emailFormRequestError: null,
    emailFormRequestStatus: RequestStatus.RUNNING,
  })),
  on(AccountActions.updateEmailSucceeded, (state) => ({
    ...state,
    emailFormRequestStatus: RequestStatus.SUCCESS,
    editEmailFormMode: FormMode.VIEW,
  })),
  on(AccountActions.updateEmailFailed, (state, { error }) => ({
    ...state,
    emailFormRequestError: error,
    emailFormRequestStatus: RequestStatus.FAILED,
  })),
  on(AccountActions.removeEmailError, (state) => ({
    ...state,
    emailFormRequestError: null,
  })),
  on(AccountActions.editEmailForm, (state, { formMode }) => ({
    ...state,
    editEmailFormMode: formMode,
  })),
);
